.frmBx{
    position: relative;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    width: 326px;
    padding: 30px;
    border-style: solid;
    border-color: rgba(75, 201, 255, 1);
    border-radius: 0 px;
    -moz-box-shadow: 0px 0px 8px rgba(201, 201, 201, 1);
    -webkit-box-shadow: 0px 0px 8px rgb(201 201 201);
    box-shadow: 0px 0px 8px rgb(201 201 201);
}
.frmCtr{
    width: 100%;
}
.usrLogo{
    width: 106px;
    height: 106px;
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
}
.loginBtn{
    background-color: rgba(243, 102, 51, 1) !important;
    width: 140px !important;
    display: block !important;
    margin: 0 auto !important;
    margin-bottom: 40px !important;
    text-transform: inherit !important;
    box-shadow: none !important;
}


.fpBX{
    position: absolute;
    width: 100%;
    left: 0 !important;
        bottom: 0;
        background-color: rgba(238, 238, 238, 1);
        text-align: center;
        padding: 14px 0;
}
.ftrBx{
        background-color: rgba(227, 227, 227, 1);
        padding: 10px 15px;
        margin-top: 50px;
}
.inputAdorment{
    position: absolute;
    right: 18px;
    top: 18px;
}
