

.header{
    background: #15717d;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* font-size: 25px; */
    font-weight: bold;
}
.sub-header{
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10%;
}
.uam-card{
    width: 30%;
    background: #15717d;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    letter-spacing: 0.5px;
    font-weight: bold;
    cursor: pointer;
    /* font-size: 16px; */
}
.user-card{
    width: 20%;
    height: 80%;
    /* border-radius: 5px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-style: dashed;
    border-width: 1px;
    margin-left: 10px;
    /* font-size: 16px; */
    font-weight: bold;
    cursor: pointer;
}
.edit-container{
    height: 320px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.add-header{
    display: flex;
    width: 100%;
    height: 60px;
}
.edit-card{
    border-style: solid;
    background-color: #ffff;
    border-width: 1px;
    height: 100%;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60%;
    border-color: #15717d;
}

.edit-label{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

select{
    width: 228px !important;
    height: 26px !important;
}

.btn-form{
    width: 98%;
    display: flex;
    justify-content: flex-end;
    height: 40px;
    align-items: flex-end;
    margin-bottom: 5px;
}

.label{
    font-size: 16px;
    font-weight: bold;
}

.btn-ht{
    display: flex;
    align-items: center;
    width: 200px !important;
    justify-content: center;
}

.add-logo {
    margin-left: 10px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn{
    background: #15717d;
    border: none;
    border-radius: 5px;
    color: white;
    height: 35px;
    margin-right: 5px;
    width: 90px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.input-card{
    width: 50%;
}

.errMsg{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input{
    width: 220px;
    height: 24px;
}

.list-filter{
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}