.MuiSelect-select{
    padding: 4px 30px 4px 6px !important;
    font-size: 13px !important;
}

.MuiMenuItem-root, .MultiSelectCheckboxListItemTxt .MuiTypography-root{
    font-size: 13px !important; 
}


