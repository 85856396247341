.filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.filter .toggle_vac {
  /* background: rgba(243, 102, 51, 1); */
  background:#15717d;
  color: #fff;
  display: flex;
  padding: 4px;
  margin-top: 4px;
  /* flex: 0 0 165px; */
}
.filter .toggle_vac a.active,
.filter .toggle_vac a {
  padding: 5px 10px;
  /* border-radius: 4px; */
  border-radius: 0.5em;
  font-weight: 400;
  list-style: none;
  font-style: normal;
  /* font-size: 14px; */
  margin: 0 4px;
}
.inline_form {
  display: flex;
}
.form_control {
  display: flex;
  align-items: center;
  /* border-right: 1px solid gray; */
  padding: 0 3px;
}

.form_control:last-child {
  border-right: none;
  padding-right: 0;
}
.form_control:nth-child(n + 5) {
  border-right: none;
  padding-right: 0;
}
.form_control label {
  white-space: nowrap;
}
.filter .toggle_vac a.active,
.filter .toggle_vac a:hover {
  background: #fff;
  /* color: rgba(243, 102, 51, 1); */
  color:#15717d;
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.4);
}
.filter_form .form_control .wrap_input .sarch_icon_input {
  padding-left: 21px;
  width: 67px;
}

.filter .filter_form select {
  height: 28px !important;
  width: 95px !important;
}

.filter .filter_form select option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.from_control .img {
  border-width: 0px;
  width: 1px;
  height: 23px;
  display: flex;
  margin: 0 10px;
  display: none;
}

.filter .filter_form {
  margin: 6px 0 0 0;
  flex: 1;
  padding-left: 6px;
}
.wrap_input {
  position: relative;
}
.wrap_input img {
  position: absolute;
  left: 5px;
  top: 7px;
}
.wrap_input input {
  padding-left: 20px;
}
.auditCheck {
  width: 18px !important;
  margin-left: 0px;
}
.toggle_sev {
  background: #15717d;
  color: #fff;
  display: flex;
  padding: 6px;
  margin-left: 4px;
}
.toggle_sev a.active,
.toggle_sev a {
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 400;
  list-style: none;
  font-style: normal;
  /* font-size: 14px; */
  margin: 0 5px;
}
.toggle_sev a.active,
.toggle_sev a:hover {
  background: #fff;
  color: black;
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.4);
}
.ongoingLabel {
  margin-top: 3px;
}

.aud {
  margin-bottom: -11px;
}
