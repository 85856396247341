.MuiTypography-root .MuiTypography-p{
    font-size: 13px;
    padding-bottom: 5px;
}



#react-doc-viewer #header-bar {
    width: 100%;
    background-color: #ccc;
  }
.myDocViewerStyle{
    width: 100%;
    /* height: 400px; */
}

.fileBx{
    background: #f2f2f2 !important;
    margin-top: 20px !important;
    border: 2px solid #80808033 !important;
    border-style: dashed !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
    padding: 5px 17px 0px 17px;
    height: 70px;
}

.fileIpt{
    background: #fff;
    padding: 3px;
    margin-right: 16px;
    width: 200px;
}
.simpleInpt{
    width: 90%;
    height: 26px;
    outline: none;
    border-radius: 3px;
    border: 1px solid #d7d7d7;
    padding-left: 10px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
}