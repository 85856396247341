.progress-conatiner{
    position: absolute;
    height: 50px;
    background: #e3e3e3;
    top: 10px;
    right: 86px;
    width: 320px;
    border-radius: 2px;
    z-index: 9;
}

.inner-box{
    height: 100%;
    width: 100%;
    /* padding: 5px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.progress-text{
    height: 60%;
    display: flex;
    align-items: center;
    padding-left: 5px;
}