.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
.quill {
  height: 80%;
}

.footer {
  height: 50px;
}

.btn-form {
  width: 98%;
  display: flex;
  justify-content: flex-end;
  height: 60px;
  align-items: center;
}

.btn {
  background: #15717d;
  border: none;
  border-radius: 5px;
  color: white;
  height: 35px;
  margin-right: 5px;
  width: 90px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.jodit-ui-search__inputs {
  display: flex;
}

.jodit-ui-search {
  top: -36px !important;
}

.jodit-ui-search__counts {
  padding-left: 5px;
  padding-bottom: 16px !important;
}

body.jodit_fullsize-box_true,
html.jodit_fullsize-box_true {
  min-height: 100% !important;
}
