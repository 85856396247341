.page-arrow {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
  }
  
.page-arrow img {
    display: inline-block;
    width: 100%;
    
  }

.Pagimagic-nav-item--prev,
.Pagimagic-nav-item--next {
    position: relative;
  }

.Pagimagic__nav-item--prev .page-arrow {
    transform: translate(-50%, -50%) rotate(-180deg);
  }
/* Pagimagic-nav-item Pagimagic-nav-item--prev disabled */