.menu {
  display: flex;
  justify-content: space-between;
  background-color: #15717D;
  /* padding: 10px; */
  min-height: 30px;
  border: 1px solid #15717D;
}

.menu ul li img {
  border-width: 0px;
  width: 11px;
  height: 7px;
}

.menu nav ul li a {
  text-decoration: none;
  /* color: #000; */
  /* color:#fff; */
}

.menu nav ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
/* .menu nav > ul > li:hover > a {
  color: #fff;
} */
/* .menu nav > ul > li svg {
  color: #15717D !important;
} */
/* .menu nav > ul > li button{
  color: #fff !important;
}*/

.menu nav > ul > li:hover button svg{
  color: #15717D !important;
} 
.menu nav > ul > li {
  color: #fff;
  display: block;
  float: left;
  padding: 7px;
  position: relative;
  text-decoration: none;
  /* transition-duration: 0.5s; */
}
.menu nav > ul > li ul {
  border: 1px solid #ddd;
  background: #fff;
}
.menu nav > ul > li ul li {
  border-bottom: 1px solid #ddd;
}
.menu nav > ul > li ul li:last-child {
  border-bottom: 0;
}
.menu nav > ul > li ul li a {
  color: #000;
  padding: 10px 15px 10px 25px;
  display: block;
  position: relative;
  background: #fff;
  /* transition: background ease-in-out 0.2s; */
}
.menu nav > ul > li ul li a:before {
  content: ".";
  font-size: 3rem;
  color: #15717D;
  position: absolute;
  top: -0.3rem;
  left: 0.4rem;
}

.menu nav > ul > li ul li:hover a:before {
  content: ".";
  font-size: 3rem;
  color: #fff;
  position: absolute;
  top: -0.3rem;
  left: 0.4rem;
}

.menu nav > ul > li ul li .active:before {
  content: ".";
  font-size: 3rem;
  color: #fff;
  position: absolute;
  top: -0.3rem;
  left: 0.4rem;
}

.menu nav > ul > li:hover {
  background: #fff;
  color: #15717D;
}

.menu nav > ul .active {
  background: #fff;
  color: #15717D;
  /* font-weight: 550; */
}

.menu nav > ul > li ul li:hover a {
  /* background: #d3d3d3; */
  background-color: #15717dad;/* rgba(227, 227, 227, 1);*/
  color: #fff;
}

.menu nav > ul > li ul li .active {
  /* background: #d3d3d3; */
  background-color: #15717dad;/* rgba(227, 227, 227, 1);*/
  color: #fff;
  /* pointer-events: none !important; */
}
.menu nav ul li ul {
  visibility: hidden;
  opacity: 0;
  /* min-width: 400px; */
  position: absolute;
  /* transition: all 0.5s ease; */
  margin-top: 8px;
  z-index: 100;
  left: 0;
  display: none;
}

.menu nav ul li:hover > ul,
.menu nav ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

.menu nav ul li ul li {
  clear: both;
  width: 100%;
  color: #000;
}

.menu .fontbold {
  /* font-weight: 550; */
  /* font-size: 0.7rem; */
  text-align: left;
  margin: 0px;
}

.menu .childmenu {
  /* font-weight: 400; */
  /* font-size: 0.8rem; */
  color: #000000;
}

.flex-container {
  display: flex;
}
.flex-child:first-child {
  margin-right: 20px;
}

.menu ul li ul.dropdown {
  white-space: nowrap;
}

.menubtn ul li ul.dropdown {
  white-space: nowrap;
}
