body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  /* font-size: 10; */
  font-family: "Roboto Medium", "Roboto Regular", "Roboto", sans-serif !important;
  line-height: 1.14286rem !important;
  /* font-weight: normal; */
  /* font-stretch: normal; */
  /* font-variant: small-caps; */
  /* letter-spacing: normal; */
  /* font-style: normal; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; 
}
*/