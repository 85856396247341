body {
  /* font-size: 10; */
  color: #2c2c2c;
}

.searchBar {
  margin-bottom: 15px;
  position: relative;
}

/* Text Colors */
.gsk-grey {
  color: #4a4a4a !important;
}

/* Buttons */
.gsk-btn {
  padding: 8px 18px !important;

  font-size: 0.65714rem !important;
  font-weight: 700 !important;
  letter-spacing: 1.2px !important;
  line-height: 1.14286rem !important;
  text-transform: uppercase !important;
  box-shadow: none !important;

  border: none;
  cursor: pointer;
}
.gsk-btn.primary,
.gsk-btn.primary.MuiButtonBase-root.Mui-selected {
  background-color: #15717d !important;
  color: #fff !important;
  text-decoration: none !important;
}
.gsk-btn.pill {
  border-radius: 2rem !important;
}

.gsk-btn.small {
  height: 1.2rem !important;
  font-size: 0.7rem !important;
  line-height: 0rem !important;
  padding: 4px 9px !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.gsk-btn:disabled {
  background-color: #b3b3b3 !important;
  color: #595959 !important;
  cursor: not-allowed;
}

.fileUpload-container .fileIcon {
  width: 40px;
}

.fileUpload-container .uploaded-files-block,
.fileUpload-container .uploaded-files-block .uploaded-file-detail {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fileUpload-container .uploaded-files-block .uploaded-file-detail {
  border: 1px solid #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.ASMToggleBtns {
  max-width: 300px;
  margin: 0 auto;
}

/* Review Page Styles */
.fileUploadIcon {
  background: #15717d;
  color: #f9f9f9;
  width: 35px !important;
  height: 35px !important;
  line-height: 43px !important;
  text-align: center;
  border-radius: 100%;
  margin-bottom: 15px;
}
.rejected-files-list {
  padding: 0 !important;
  margin: 0 !important;
  list-style-type: none !important;
}

.rejected-files-list li {
  list-style-type: none !important;
  /* margin-bottom: 10px; */
}

.Pagimagic__nav-item {
  line-height: 32px !important;
  width: 32px !important;
  height: 32px !important;
  border: 1px solid #15717d !important;
  color: #15717d !important;
}
.Pagimagic__nav-item.Pagimagic__nav-item--active {
  line-height: 32px !important;
  width: 32px !important;
  height: 32px !important;
  border: 1px solid #15717d !important;
  background-color: #15717d !important;
  color: rgb(255, 255, 255) !important;
}

.Pagimagic__nav-item.Pagimagic__nav-item--prev .Pagimagic-nav-arrow,
.Pagimagic__nav-item.Pagimagic__nav-item--next .Pagimagic-nav-arrow {
  width: 18px !important;
  height: 18px !important;
  text-align: center !important;
  padding: 3px 8px !important;
  line-height: 32px !important;
  fill: #15717d !important;
}

.Pagimagic__nav-item.Pagimagic__nav-item--prev .Pagimagic-nav-arrow {
  padding: 6px 8px !important;
}
.MuiPaper-root {
  box-shadow: none !important;
}
.modalContent {
  padding: 3px 20px 18px 50px;
  border: 1px silver;
  border-style: dashed;
}
.modalContent p {
  font-size: 1rem;
  line-height: 1.7;
  word-break: break-word;
  text-align: justify;
}
.tags-container ul li {
  padding: 5px;
  display: inline-block;
  margin-left: 0px !important;
  font-size: 13px;
  border-width: 1px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 1em;
  margin-top: 1em;
}
.tagsinfo ul li {
  margin-top: 0px !important;
}
.tags-container ul {
  padding-inline-start: 0em;
}
.tags-container ul li:hover {
  background-color: #15717d;
  color: #fff;
  border: 1px solid #15717d;
}
.tags-container ul .file-view:hover {
  cursor: pointer;
}
.bg-box {
  width: 100%;
  height: 100vh;
  z-index: 9999 !important;
  position: absolute;
  top: 0;
}
.loader {
  justify-content: center;
  align-items: center;
  top: calc(50% - 35px);
  position: absolute;
  left: calc(50% - 35px);
  z-index: 9999 !important;
}
.no-data {
  text-align: center;
  width: 570px;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  height: calc(100% - 112px);
  padding: 0;
  display: -webkit-flex;
  margin: auto;
}
.wrap {
  border: 1px solid #ccc;
  margin-top: 1em;
  box-sizing: border-box;
  padding: 10px;
  background: #f8f8ff;
}
.MuiPopover-paper {
  width: auto !important;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding-right: 3px;
}
.flex-export {
  margin-right: 175px;
  margin-top: 5px;
}
.flex-download {
  position: absolute;
  right: 15px;
}
.infopopup {
  width: 250px;
  height: auto;
  border: 1px solid #ccc;
  font-size: small;
  line-height: 1.14rem;
  letter-spacing: 1.2px;
  box-sizing: border-box;
  padding: 2px;
}
.infoicon {
  padding: 20px 10px;
  cursor: pointer;
}
.file-input {
  display: none !important;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .file-selection {
    margin-left: 464px;
  }
}
.MuiTooltip-tooltip {
  white-space: pre-line;
}

.stk {
  pointer-events: none;
}
.notAllowed {
  /* pointer-events: none;
  opacity: 0.6; */
  pointer-events: none !important;
  background-color: #b3b3b3 !important;
  color: #595959 !important;
}

.notAllowed a {
  /* pointer-events: none;
  opacity: 0.6; */
  pointer-events: none !important;
  background-color: #b3b3b3 !important;
  color: #595959 !important;
}

.notAllowed a button {
  /* pointer-events: none;
  opacity: 0.6; */
  pointer-events: none !important;
  /* background-color: #b3b3b3 !important; */
  color: #595959 !important;
}

.react-tooltip-normal-font {
  padding: 8px 18px !important;
  font-size: 0.1rem !important;
  font-weight: 400 !important;
  letter-spacing: 1.2px !important;
  line-height: 1.14286rem !important;
}

.MuiMenuItem-root:hover {
  background: #15717dad !important;
  color: #fff !important;
}

.MuiDataGrid-toolbarContainer
  .MuiButton-textPrimary
  .MuiButton-startIcon
  .MuiSvgIcon-root {
  color: #15717d;
}

button {
  letter-spacing: 1.2px !important;
  /* line-height: 1.14286rem !important; */
}

::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

/* Track */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  box-shadow: inset 0 0 6px #1ca8c1 !important;
  -webkit-border-radius: 3rem;
  border-radius: 3rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3rem;
  border-radius: 3rem;
  background: #15717d !important;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  box-shadow: inset 0 0 6px #1ca8c1 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #15717dad !important;
}

.css-rq9a2a-diff-removed {
  background: #fff !important;
  border-bottom: 2px solid #15717d !important;
}

.css-cnnxkz-diff-added {
  background: #fff !important;
}

.css-hf3w1f-word-removed {
  background: rgb(228 0 48 / 72%) !important;
}

.css-1u4zuq6-word-added {
  background: hwb(152deg 0% 47% / 66%) !important;
}

.css-17vezug-marker.css-rq9a2a-diff-removed pre {
  display: none;
}

.css-17vezug-marker.css-cnnxkz-diff-added pre {
  display: none;
}

.jodit-workplace {
  white-space: pre-wrap;
}

.button-grid-export-excel {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(21, 113, 125);
  border-radius: 2rem;
  font-family: "Roboto Medium", "Roboto Regular", Roboto, sans-serif !important;
  padding: 8px 18px !important;
  font-size: 0.65714rem !important;
  font-weight: 100 !important;
  line-height: 1.14286rem !important;
  box-shadow: none !important;
  text-transform: uppercase !important;
}

.dataframe {
  /* border: 2px solid #15717d !important; */
  border: 2px solid rgba(224, 224, 224, 1) !important;
  /* border-radius: 0.5rem; */
  border-collapse: collapse !important;
  /* margin-top: 10px; */
  /* white-space: nowrap; */
  /* width: 100%;
  table-layout: fixed; */
}

/* .insights-main-container .dataframe tbody {
  display: block;
  overflow-x: auto;
} */

.insights-main-container .enablescroll {
  /* overflow-x: auto; */
  /* max-width: 92%; */

  overflow-x: auto;
  overflow-y: auto;
  max-width: 92%;
  max-height: 500px;
  display: inline-block;
}

.jodit-wysiwyg .enablescroll {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  max-height: fit-content;
}

.insights-main-container .insightsummary {
  display: none;
  padding: 0;
}

.jodit-wysiwyg .insightsummary {
  display: none;
  padding: 0;
}

.dataframe thead tr {
  background-color: #15717d;
}

.dataframe th {
  /* border: 2px solid #15717d; */
  padding: 11px !important;
  border-collapse: collapse !important;
  background-color: #15717d;
  color: #fff;
  text-align: center;
  /* position: relative; */
  position: sticky;
  top: 0;
}

.dataframe th:not(:last-child):after {
  content: "|";
  font-size: 1.1092436974789917rem;
  color: #fff !important;
  position: absolute;
  right: -3px;
  margin-top: -2px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 0.4rem;
  height: 0.1rem;
  display: -webkit-inline-box;
}

.dataframe td {
  /* border: 2px solid #15717d !important; */
  padding: 11px !important;
  border-collapse: collapse !important;
  border: none !important;
}

.dataframe tr:nth-child(even) {
  background-color: #ffffff;
}

.dataframe tr:nth-child(odd) {
  background-color: #efefed;
}

.insight-inner-container {
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);*/
  line-height: 1.5em;
  margin: 0;
  max-width: 90%;
  margin: auto;
  padding: 10px;
  margin-bottom: 20px;
  white-space: normal !important;
}

.pl21_m0 {
  padding-left: 21px;
  margin: 0;
}

.pl21 {
  padding-left: 21px;
}

.pl21_pb5 {
  padding-left: 21px;
  padding-bottom: 5px !important;
}

.pl21_pb5_m0 {
  padding-left: 21px !important;
  padding-bottom: 5px !important;
  margin: 0;
}

.m0_pb5 {
  margin: 0;
  padding-bottom: 5px !important;
}

.pl21_pb5_m0_flleft_w100 {
  padding-left: 21px !important;
  padding-bottom: 5px !important;
  margin: 0;
  float: left;
  width: 100%;
}

.phy-seperator {
  border-bottom: 4px double #15717d;
  width: 100%;
}

.pt25_pr0_pb25_pl0 {
  padding: 25px 0 25px 0;
}

.pb20 {
  padding-bottom: 20px;
}

.insight-info-custom {
  margin-left: 8px;
  padding: 8px;
  background-color: #efefed;
  border-left: 6px solid #15717d;
  display: inline-flex;
  border-radius: 0.1rem;
}

.insight-info-custom p {
  font-size: 10px;
  font-weight: bold;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
}

.insight-info-custom p:not(:last-child) {
  border-right: 2px solid #15717d;
}

.insight-info-custom span {
  font-size: 8px !important;
  font-weight: normal !important;
}

.insights-main-container table tr td {
  border: 1px solid #dadada;
  min-width: 2em;
  padding: 0.4em;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  vertical-align: middle;
}

.insights-main-container img {
  max-width: 100%;
  position: relative;
}
/* Microsoft Edge Browser 15+ - @supports method */

@supports (-ms-ime-align: auto) {
  .ftrBx {
    margin-top: 100px !important;
  }
}
