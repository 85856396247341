.advanceCont{
    background-color: #fff;
 
}
.checkBxGroup{
    border: 1px solid #333333b0 !important;
    padding: 10px 15px !important;
    border-radius: 10px !important;
    border-style: dashed !important;
}
.advanceCont.active {
    display: none;
}

.advanceCont.disactive {
    display: block;
    position: relative;
}
.crossIcon{
    position: absolute;
    background: #15717d;
    color: #fff !important;
    font-size: 20px !important;
    border-radius: 3px;
    right: 10px;
    top: 10px;
    cursor: pointer;
}


.AdvanceSbx{
    background: #dcdcdc40;
    padding:5px 10px 5px 10px;
}

.nodata.disactive{
    display: none;
}