/* body {
  background: rgba(234, 234, 234, 0.996078431372549);
} */
.container-fluid-invaliduser {
  text-align: center;
  margin: 0 auto;
}

.container-main-invaliduser {
  padding: 60px 0px 60px 0px;
  /* background: #f2f2f2; */
}

.css-1pqbxu3-invaliduser {
  padding: 30px 40px 40px 40px;
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgba(128, 128, 128, 0.2) !important;
  display: inline-block;
}
