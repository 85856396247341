.menuBox {
  color: white;
  border-radius: 2px;
  text-decoration: none;
  background-color: #15717d;
  letter-spacing: 0.08rem;
  padding: 0.8rem !important;
  font-size: 0.85em !important;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
.css-1pqbxu3 {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  background-color: rgb(255, 255, 255);
  border: 2px dashed rgba(128, 128, 128, 0.2) !important;
}
