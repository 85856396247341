.bBx{
    font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto', sans-serif !important; 
    font-weight: bolder;
    font-style: normal;
    font-size: 12px;
    color: #000000d5;
    position: absolute;
    right: 0;
    bottom: 174px;
}
.bBx_ara{
    font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto', sans-serif !important; 
    font-weight: bolder;
    font-style: normal;
    font-size: 12px;
    color: #000000d5;
    position: absolute;
    display: flex;
    right: 9px;
    bottom: auto;
    top: 17px;
    transform: translateX(-235px);
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.asmIcon{
    left: -15px;
    position: relative;
    background: #4bc9ff;
    border-radius: 75%;
    padding: 10px;
    width: 55px;
    height: 55px;
    top: 26px;
    cursor: pointer;
}
.asmP{
    position: relative;
    left: -10px;

}

.araIcon{
    left: 9px;
    position: relative;
    top: -3px;
    cursor: pointer;
}

.araP{
    
    padding-bottom: 10px;
    left: 10px;
    position: relative;

}