/* .dataRowRecords {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
} */
.searchTbl>tr>td:first-child{
    color: #202124;
    line-height: 1.58;
    border: inherit;
    font-size: 14px;
    padding-left: 0px;
    padding-bottom: 0px;
}
.searchTbl>tr>td:first-child>p{
   padding:0px;
   margin-top: 0px;
}
.searchTbl>tr>td:nth-child(2){
    display: table-row;
    color: #1a0dab;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
    border: inherit;
}

.searchTbl>tr>td:nth-child(2) a{
    text-decoration: none;
}



.searchTbl>tr>td:last-child{
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* line-height: 16px;  */
    max-height: 87px; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    color: #4d5156;
    line-height: 1.58;
    font-size: 14px;
    border-bottom: 15px solid #fff;
    position: relative;
    left: -15px;
    box-shadow: 0px 1px 0px #8080806b;
    margin-bottom: 15px;

}
.searchTbl>tr:last-child>td:last-child {
   box-shadow: inherit;

}