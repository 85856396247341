.pillInput {
  padding: 12px 14px;
  background: #eee;
  /* border: 1px solid red; */
  border: none;
  border-radius: 2px;

  padding:2px 2px 2px 10px;
  height: 30px;
  width: 380px;
  background-color: transparent;
  border: 1px solid #8C8C8C;
}

.pillInput:focus {
  border: 1px solid #000;
  outline: inherit;
}

.pillInput i {
  position: absolute;
}
