.table-container{
    height: 100%;
    width: 100%;
    border: none;
    border-collapse: collapse;
}

.th-container{
    background-color: #15717d;
    color: #fff;
    height: 40px;
}

td{
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.tbody-font{
    color: rgba(0, 0, 0, 0.87);
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.tr-row-tr{border-bottom: 1px solid rgb(223 220 220);}

.td-check{
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.add-pipe{
    position: relative;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    min-width: 150px;
    height: 100%;
}

.add-pipe::after {
    content: "| ";
    position: absolute;
    font-size: 20px;
    top: 5px;
    left: 0;
    font-weight: bold;
    /* font-family: auto; */
}

.checkbox-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-checkbox{
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 1;
}

.export-btn{
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    color: #1976d2;
    cursor: pointer;
}

.fnt-sz{
    font-size: 16px;
}

.svg-spn{
    font-size: 16px;
    display: flex;
    margin-right: 8px;
}

.tr-row:nth-child(even) {background: rgb(230, 229, 229)}
.tr-row:nth-child(odd) {background: #FFF}
.tr-row{
    padding: 0 10px;
}

.theader{
    border-radius: 4px;
    height: auto;
    border: 1px solid rgba(224, 224, 224, 1);
    flex: 1;
    color: rgba(0, 0, 0, 0.87);
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    outline: none;
    display: flex;
    flex-direction: column;
}

.tsubh{
    flex-wrap: wrap;
    padding: 4px 4px 0px;
    position: relative;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    justify-content: space-between;
}

.thtext{
    margin: 8px;
    padding: 8px;
    font-size: 1.2rem;
    font-weight: 700;
    display: flex;
    -webkit-box-pack: center;
}