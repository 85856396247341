.clsy-title{
    /* font-family: 'Roboto Bold', 'Roboto Regular', 'Roboto', sans-serif; */
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    margin: 0px;
    color: #333333;

}
.clsy-title .round-bx{
    border: 1px solid #f36633;
    background: #f36633;
    color: #fff;
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 75%;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    margin-right: 10px;
}

.sdocBx{
    background: #f2f2f2 !important;
    margin-top: 20px !important;
    height: 100px !important;
    border: 2px solid #80808033 !important;
    border-style: dashed !important;
    box-shadow: none !important;
}
.sdocBx p{
    /* font-family: 'Arial', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #AAAAAA;
}
.sdocBx input{
    background: #fff;
    padding: 3px;
    color: #0097AC;
}
.tdocBx textarea{
    width: 100%;
    margin-top: 0px;
    background: rgb(242 242 242);
    border: 1px solid gainsboro;
    margin-bottom: 20px;
}

.tdocBx textarea:focus-visible{
outline: none;
color: #333333;
}
#demo-multiple-checkbox{
    padding: 2px;
    font-size: 13px;
}
.fromSpan, .toSpan{
    /* font-family: 'Arial', sans-serif; */
    font-weight: 400;
    font-style: normal;
    color: #169BD5;
    font-size: 14px;
    padding-right: 15px;
}
.lanSelect{
    width: 163px;
    border: none;
    border-bottom: 1px solid gainsboro;
    height: 25px;
    margin-right: 20px;
    color: #232222;
}

.lanSelect:focus-visible{
    outline: none;

}
/* .transBtn{
    width: 90px;
    height: 30px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(121, 121, 121, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #169BD5;
    cursor: pointer;
}
.transBtn:disabled{
    width: 90px;
    height: 30px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(121, 121, 121, 1);
    border-radius: 5px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto', sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #999;
    cursor:not-allowed;
} */
.arroIcn{
    position: relative;
    top: 10px;
    padding-right: 5px;
}
.tagsBtn{
    width: 86px;
    height: 24px;
    background: inherit;
    background-color: rgba(22, 155, 213, 1);
    border: none;
    border-radius: 3px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* font-family: 'Arial', sans-serif; */
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    color: #fff;
    position: relative;
    right: 0px;
    display: block;
    float: right;
    margin-bottom: 20px;
    cursor: pointer;
}