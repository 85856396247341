/* .Pagimagic-nav > *{
    vertical-align: middle;
  }
  
  .Pagimagic-nav-item {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: solid 1px #000;
    border-radius: 3px;
    font-size: 18px;
    margin: 0 5px;
    cursor: pointer;
    transition: color .15s, background .15s;
  }
  
  .Pagimagic-nav-item.active {
    background: #000;
    color: #fff;
  }
  
  .Pagimagic-nav-item--prev,
  .Pagimagic-nav-item--next {
    border: none;
  }
  
  .Pagimagic-nav-item--prev.disabled,
  .Pagimagic-nav-item--next.disabled {
    opacity: .3;
  } */

  .MuiChip-root.MuiChip-filled {
    background: #15717d !important;
    color: #ffffff !important;
  }