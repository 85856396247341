/* body {
  background-color:#EFEFED !important;
} */

.container-fluid {
  max-width: 100%;
  margin: 0 auto;
  /* font-family: 'Roboto Medium', 'Roboto Regular', 'Roboto', sans-serif !important; */
}

.container-main {
    padding: 15px;
    /* background: #f2f2f2; */
}

.leftsidebar{
  float: left;
}
