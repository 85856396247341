/* body {
  background-color:#EFEFED !important;
} */
.container-fluid {
  max-width: 100%;
  margin: 0 auto;
}
.container-main {
  padding: 10px 8px;
  /* background: #f2f2f2; */
}
.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menuBox {
  color: white;
  border-radius: 2px;
  text-decoration: none;
  background-color: #15717d;
  letter-spacing: 0.08rem;
  padding: 0.8rem !important;
  font-size: 0.85em !important;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
}
#fbText {
  background-color: #15717d;
  text-align: center;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
}
.help-text {
  color: #15717d !important;
  font-size: 12px !important;
  float: right;
  margin-right: 60px !important;
  margin-top: 1px !important;
}
.feedback-text {
  white-space: pre-line;
  line-height: 1.3em;
  width: 90%;
  max-width: 90%;
}
.auditForm > * {
  flex: 1;
}
h4.aud_que {
  padding-top: 10px;
}
.linkOpen {
  color: #15717d;
  cursor: pointer;
}
#noEdit {
  pointer-events: none !important;
}
.flex-export {
  margin: 0 21px 0 0;
  display: flex;
  justify-content: space-between;
}