.mTab{
    text-transform: initial !important;
    min-height: 31px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    line-height: 16px !important;
    background: #d7d7d7 !important;
    /* font-family: 'Roboto', sans-serif !important; */
    font-weight: 400 !important;
    font-style: normal !important;
    color: #333333 !important;
    font-size: 13px !important;
    border: 1px solid #d7d7d7 !important;
}
.Mui-selected.mTab{
    color: #000 !important;
    background: #fff !important;
    font-size: 13px !important;
    border-top: inherit !important;
}
.searchBar1{
    position: relative;
    /* margin-bottom: 15px; */
    margin: 10px 0px -36px 10px;
}

.searchFoorm{
    width: 599px;
     position: relative;
}
.noData.disactive{
    display: none;
}

.noDatap{
    padding-left: 47px;
    position: relative;
    color: #a59e9e;
}

.nodataIcon{
    width: 50px;
    position: absolute;
    left: -4px;
    top: -12px;
}
